import React, { useEffect } from 'react'

import * as Sentry from '@sentry/browser'
import SuperTokens from 'supertokens-auth-react'
import Session from 'supertokens-auth-react/recipe/session'

const AttemptRefreshPage: React.FC<{}> = React.memo(() => {
  useEffect(() => {
    let cancel = false

    Session.attemptRefreshingSession()
      .then((success) => {
        if (cancel) {
          // component has unmounted somehow..
          return
        }
        if (success) {
          // we have new session tokens, so we redirect the user back
          // to where they were.
          const urlParams = new URLSearchParams(window.location.search)
          window.location.href = urlParams.get('redirectBack')! || '/'
        } else {
          // we redirect to the login page since the user
          // is now logged out
          SuperTokens.redirectToAuth()
        }
      })
      .catch((error) => {
        // We want to figure out if there are any errors during attempt refresh.
        // Since any errors caught here may not be from session refresh specifically,
        // we will re-throw the error after logging it.
        console.error(error)
        Sentry.captureException(error)
        throw error // replicate behaviour
      })

    return () => {
      cancel = true
    }
  }, [])
  return null
})

export default AttemptRefreshPage
