import * as Sentry from '@sentry/browser'
import axios, { AxiosRequestConfig } from 'axios'
import Session from 'supertokens-auth-react/recipe/session'

import { handleDates } from '@hypotenuse/common/src/utils/Functions'

import { SERVER } from './Constants'

export const apiClient = axios.create({
  baseURL: SERVER,
  // Send browser cookies to the server
  withCredentials: true
})

const _handleAttemptSessionRefresh = async (
  config: AxiosRequestConfig<any>
) => {
  try {
    await Session.attemptRefreshingSession()
    return new Promise((resolve) => {
      resolve(axios(config))
    })
  } catch (error) {
    // We want to figure out if there are any errors during attempt refresh.
    // Since any errors caught here may not be from session refresh specifically,
    // we will re-throw the error after logging it.
    console.error(error)
    Sentry.captureException(error)
    throw error // replicate behaviour
  }
}

/**
 * This interceptor casts valid date strings into the `Date` class.
 */
apiClient.interceptors.response.use(
  async (originalResponse) => {
    if (originalResponse.request.responseURL.includes('/refresh-session')) {
      await _handleAttemptSessionRefresh(originalResponse.config)
    }
    handleDates(originalResponse.data)
    return originalResponse
  },
  async (error) => {
    if (
      error?.response?.status === 405 &&
      error?.response?.request.responseURL.includes('/refresh-session')
    ) {
      await _handleAttemptSessionRefresh(error.config)
    }
    // Ensure the error is rethrown after it is intercepted
    // This is important as the error will not be caught and is treated as a valid response otherwise
    return Promise.reject(error)
  }
)
